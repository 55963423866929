import Card from '@mui/material/Card'
import Button  from '@mui/material/Button'
import React from 'react'
import CardContent from '@mui/material/CardContent'
import { CardActions, Grid } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios'




const Tramites = (props) => {

    const theme = createTheme({
        palette: {
          primary: {
            // Purple and green play nicely together.
    
            main: '#66bb6a',
          },
          secondary: {
            // This is green.A700 as hex.
            main: '#11cb5f',
          },
        },
    })

    const { previousStep, step, steps, triggerNextStep } = props
    var token = "amFtdW5kaUBnbWFpbC5jb206RzNuM3IxYy04OTAzOTkwNDY="

    var objeto = {
      IDConversacion: localStorage.getItem("conversacion"),
      pregunta: previousStep.message,
      respuesta: "",
      idTramite: 0,
      isTramite: 0,
      nit: localStorage.getItem("nit"),
      url: window.location.origin,
      ip: localStorage.getItem("IP"),
      idUser:localStorage.getItem("idUser")
    }
    
  return (
    <>
        <Grid container spacing={2}>
            <Grid item xs={6} >
                <FormControl fullWidth>
                    <ThemeProvider theme={theme}>
                          <Button variant="contained" onClick={() => {
                              objeto.respuesta = "PAGOS"
                              
                              axios.post('https://apichatbot.1cero1.com/ChatBot/conversacion/log', objeto, {
                                    headers: {'Authorization': `Basic ${token}`}})
                              triggerNextStep({ trigger: 'PAGO' })
                          }}>PAGOS</Button>
                    </ThemeProvider>
                </FormControl>
            </Grid>
              
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <ThemeProvider theme={theme}>
                          <Button variant="contained" onClick={() => { 
                              objeto.respuesta = "PQRSD"
                              axios.post('https://apichatbot.1cero1.com/ChatBot/conversacion/log', objeto, {
                                headers: {'Authorization': `Basic ${token}`}})
                              triggerNextStep({ trigger: 'PQR' })   
                          }}>PQRSD</Button>
                    </ThemeProvider>
                </FormControl>
            </Grid>
              
        </Grid>
    </>
  )
}

export default Tramites