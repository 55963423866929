import Card from '@mui/material/Card'
import Button  from '@mui/material/Button'
import React from 'react'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { CardActions, Grid } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import axios from 'axios'


const TarjetaPagos = () => {

    var token = "amFtdW5kaUBnbWFpbC5jb206RzNuM3IxYy04OTAzOTkwNDY="

    var objeto = {
        IDConversacion: localStorage.getItem("conversacion"),
        pregunta: "tipo de Tramite",
        respuesta: "",
        idTramite: 0,
        isTramite: 1,
        nit: localStorage.getItem("nit"),
        url: window.location.origin,
        ip: localStorage.getItem("IP"),
        idUser:localStorage.getItem("idUser")
      }
   
    return (
        <>
            <Grid container>
                <Grid item>
                    <Card sx={{
                        border: "",
                        margin: "5px"
                    }}>
                        <CardContent>
                            <Typography variant="h5">Impuesto Predial</Typography>
                            <Typography variant="body2" textAlign="left">Para realizar el proceso de liquidación del impuesto predial haz clic en el siguiente botón:</Typography>
                            <CardActions>
                                <FormControl fullWidth>
                                    <Button variant="contained" color="success" onClick={() => {
                                         objeto.idTramite=1
                                         axios.post('https://apichatbot.1cero1.com/ChatBot/conversacion/log', objeto, {
                                            headers: {
                                              'Authorization': `Basic ${token}` 
                                            }
                                        })
                                        window.open('https://portaltributario.jamundi.gov.co/smarttmtsuite-web-jamundi/faces/site/portal_userLogin.xhtml')
                                    }} >impuesto predial</Button>
                                </FormControl>
                            </CardActions>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <Card sx={{
                        border: "",
                        margin: "5px"
                    }}>
                        <CardContent>
                            <Typography variant="h5">Impuesto Industria y comercio</Typography>
                            <Typography variant="body2" textAlign="left">En tiempos de cuarentena nuestra Alcaldía se renueva para ti, por esta razón tenemos habilitado el servicio:</Typography>
                            <CardActions>
                                <FormControl fullWidth>
                                    <Button variant="contained" color="success" onClick={() => {
                                         objeto.idTramite=2
                                         axios.post('https://apichatbot.1cero1.com/ChatBot/conversacion/log', objeto, {
                                            headers: {
                                              'Authorization': `Basic ${token}` 
                                            }
                                        })
                                        window.open('https://portaltributario.jamundi.gov.co/smarttmtsuite-web-jamundi/faces/site/portal_userLogin.xhtml')
                                    }}>impuesto industria y comercio</Button>
                                </FormControl>
                            </CardActions>
                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid item width={'100%'}>
                    <Card  sx={{
  
                        margin: "5px"
                    }}>
                        <CardContent>
                            <Typography variant="h5">¿Te puedo ayudar en algo más?</Typography>
                            <CardActions>
                                <FormControl fullWidth>
                                    <Button variant="contained" onClick={() => triggerNextStep({ trigger: 'opciones' })}>si</Button>
                                </FormControl>
                                <FormControl fullWidth>
                                    <Button variant="contained" onClick={() => triggerNextStep({ trigger: 'despedida' })}>no</Button>
                                </FormControl>
                            </CardActions>
                        </CardContent>
                    </Card>
                </Grid> */}
            </Grid>
          
           

            
      </>

  )
}

export default TarjetaPagos