import Card from '@mui/material/Card'
import Button  from '@mui/material/Button'
import React from 'react'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { CardActions, Grid } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import axios from 'axios'


const TarjetaPQRSD = () => {

    var token = "amFtdW5kaUBnbWFpbC5jb206RzNuM3IxYy04OTAzOTkwNDY="

    var objeto = {
        IDConversacion: localStorage.getItem("conversacion"),
        pregunta: "tipo de Tramite",
        respuesta: "",
        idTramite: 0,
        isTramite: 1,
        nit: localStorage.getItem("nit"),
        url: window.location.origin,
        ip: localStorage.getItem("IP"),
        idUser:localStorage.getItem("idUser")
      }


    return (
        <>
            <Grid container>
                <Grid item>
                    <Card sx={{
                        border: "",
                        margin: "5px"
                    }}>
                        <CardContent>
                            <Typography variant="h5">PQRSD</Typography>
                            <Typography variant="body2" textAlign="left">Puedes registrar tu petición, queja, reclamo, sugerencia o denuncia a través de nuestro portal ingresando aquí:</Typography>
                            <CardActions>
                                <FormControl fullWidth>
                                    <Button variant="contained" color="success" onClick={() => {
                                         objeto.idTramite=3
                                         axios.post('https://apichatbot.1cero1.com/ChatBot/conversacion/log', objeto, {
                                            headers: {
                                              'Authorization': `Basic ${token}`
                                            }
                                        })
                                        window.open('http://www.sisnet.com.co/jamundipqr/#no-back-button')
                                    }}>realizar PQRSD</Button>
                                </FormControl>
                            </CardActions>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>     
      </>

  )
}

export default TarjetaPQRSD